import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import { forwardRef } from "react";

const TextField = styled(
  forwardRef((props: Omit<TextFieldProps, "variant">, ref: any) => (
    <MuiTextField variant={"outlined"} {...props} />
  ))
)``;

export default TextField;

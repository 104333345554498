/* eslint-disable react-hooks/rules-of-hooks */
import { ComponentType, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Exact } from "@hungphongbk/vth-sdk";
import {
  refetchShowcaseForUpdateQuery,
  refetchShowcasesQuery,
  ShowcaseDetailQuery,
  useCreateShowcaseMutation,
  useShowcaseForUpdateLazyQuery,
  useUpdateOneShowcaseMutation,
} from "../../types/graphql";
import { LazyQueryResult } from "@apollo/client";

type ShowcaseWrapperHocProps = {
  mode: "add" | "edit";
  slug: string;
  fetchOneShowcase: () => Promise<
    LazyQueryResult<ShowcaseDetailQuery, Exact<{ slug: string }>>
  >;
  createShowcase: ReturnType<typeof useCreateShowcaseMutation>[0];
  updateShowcase: ReturnType<typeof useUpdateOneShowcaseMutation>[0];
  showcase: ShowcaseDetailQuery;
};
export default function ShowcaseWrapperHoc(
  Component: ComponentType<ShowcaseWrapperHocProps>
) {
  return function (props: any): JSX.Element {
    const { slug } = useParams<"slug">(),
      mode = slug === "add" ? "add" : "edit";

    const refetchers = useMemo(() => {
      return [
        refetchShowcasesQuery({
          size: 5,
          filter: {},
        }),
        refetchShowcaseForUpdateQuery({ slug: slug as string }),
      ];
    }, [slug]);

    const [fetchOneShowcase, { data: showcase }] =
        useShowcaseForUpdateLazyQuery({
          variables: { slug: slug as string },
        }),
      [createShowcase] = useCreateShowcaseMutation({
        refetchQueries: [refetchers[0]],
      }),
      [updateShowcase] = useUpdateOneShowcaseMutation({
        refetchQueries: refetchers,
      });

    useEffect(() => {
      if (mode === "edit") {
        // noinspection JSIgnoredPromiseFromCall
        fetchOneShowcase();
      }
    }, [mode, fetchOneShowcase]);

    return (
      <Component
        mode={mode}
        slug={slug}
        fetchOneShowcase={fetchOneShowcase}
        createShowcase={createShowcase}
        updateShowcase={updateShowcase}
        showcase={showcase!}
        {...props}
      />
    );
  };
}

import { useMemo } from "react";
import { SizeMeProps, withSize } from "react-sizeme";
import { Box } from "@mui/material";
import templates, { mailDeserialize } from "./templates";
import { MailTemplateDto } from "../../../types/graphql";

const TemplatePreview = withSize()(function TemplatePreview({
  preview,
  size,
}: SizeMeProps & {
  preview: MailTemplateDto;
}): JSX.Element {
  const deserialized = useMemo(() => {
    return mailDeserialize(preview._serializedNode ?? templates[preview.id]);
  }, [preview]);

  return (
    <Box
      sx={{
        "> *": {
          transform: `scale(${size!.width! / 544})`,
          transformOrigin: "left top",
        },
      }}
    >
      <Box sx={{ width: 544 }}>{deserialized}</Box>
    </Box>
  );
});

export default TemplatePreview;

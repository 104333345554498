import { useNavigate, useParams } from "react-router";
import { useOneMailTemplateQuery } from "../../../types/graphql";
import { Dialog, DialogTitle } from "@mui/material";
import EditorInner from "./editor-inner";
import { useCallback } from "react";

type MailTemlateEditorProps = {};
export default function MailTemplateEditor(props: MailTemlateEditorProps) {
  const navigate = useNavigate();
  const { id } = useParams<"id">();

  const { data } = useOneMailTemplateQuery({
    variables: { id: id as string },
  });

  const onClose = useCallback(
    () => navigate("/settings/mail-template"),
    [navigate]
  );

  return data && data.mailTemplateDto ? (
    <Dialog open={true} maxWidth={"lg"} fullWidth onClose={onClose}>
      <DialogTitle>
        Chỉnh sửa template {data.mailTemplateDto.templateName}
      </DialogTitle>
      <EditorInner template={data.mailTemplateDto!} onClose={onClose} />
    </Dialog>
  ) : null;
}

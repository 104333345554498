import {
  Box,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useCurrentUserQuery } from "../types/graphql";

type UserToolbarProps = unknown;
export default function UserToolbar(props: UserToolbarProps): JSX.Element {
  const { data, loading } = useCurrentUserQuery();
  return (
    <Toolbar
      sx={{
        pl: { xs: 2, sm: 2 },
        borderBottom: "1px solid #dfdfdf",
        height: 80,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <IconButton sx={{ height: 40, width: 40, bgcolor: "#ededed" }}>
            {data!.currentUser!.name[0]}
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography variant={"body2"}>{data!.currentUser!.name}</Typography>
            <Typography
              variant={"caption"}
              sx={{ fontWeight: 600 }}
              color={
                /SUPERADMIN/.test(data!.currentUser!.role)
                  ? "error.main"
                  : "warning.main"
              }
            >
              {data!.currentUser!.role}
            </Typography>
          </Box>
        </>
      )}
    </Toolbar>
  );
}

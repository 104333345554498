import { MediaDto } from "@hungphongbk/vth-sdk";
import { Box, styled, SxProps } from "@mui/material";

type ThumbnailProps = { src: MediaDto; sx?: SxProps; size?: number };

const StyledThumbnail = styled(Box)<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function Thumbnail({
  size = 60,
  ...props
}: ThumbnailProps): JSX.Element {
  return (
    <StyledThumbnail sx={props.sx} size={size}>
      <img src={props.src.path} />
    </StyledThumbnail>
  );
}

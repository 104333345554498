import { Stack } from "@mui/material";
import BannerUpload from "./BannerUpload";
import PageTitle from "../../components/PageTitle";

type CommonSettingsProps = unknown;
export default function CommonSettings(
  props: CommonSettingsProps
): JSX.Element {
  return (
    <>
      <PageTitle>Thông tin Website</PageTitle>
      <Stack gap={2}>
        {/*<SiteInfoConfig />*/}
        <BannerUpload />
      </Stack>
    </>
  );
}

import { useCallback, useReducer } from "react";
import { PageInfo } from "../types/graphql";
import { omit } from "lodash";

type ActionType = "goNext" | "goPrev" | "changePageSize" | "setPageInfo";
type PayloadableAction = { type: ActionType; payload?: any };

type State = {
  page: number;
  size: number;
  direction: "next" | "prev";
  pageInfo?: PageInfo;
  before?: string;
  after?: string;
};

const reducer = (state: State, action: PayloadableAction): State => {
  switch (action.type) {
    case "goNext":
      return {
        ...omit(state, ["before"]),
        page: state.page + 1,
        direction: "next",
        after: state.pageInfo!.endCursor,
      };
    case "goPrev":
      return {
        ...omit(state, ["after"]),
        page: state.page - 1,
        direction: "prev",
        before: state.pageInfo?.startCursor,
      };
    case "setPageInfo":
      return {
        ...state,
        pageInfo: action.payload,
      };
    case "changePageSize":
      return {
        page: 0,
        size: action.payload!,
        direction: "next",
      };
  }
};

export function useDatagridRelayCursorPagination() {
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    size: 5,
    direction: "next",
  });

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > state.page) dispatch({ type: "goNext" });
      else dispatch({ type: "goPrev" });
    },
    [state.page]
  );

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    dispatch({ type: "changePageSize", payload: newPageSize });
  }, []);

  const setPageInfo = useCallback((pageInfo: PageInfo) => {
    dispatch({ type: "setPageInfo", payload: pageInfo });
  }, []);

  return {
    ...state,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  };
}

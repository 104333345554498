import PageTitle from "../../components/PageTitle";
import { Link, matchPath, Outlet, useLocation } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

type UserManagementProps = {};
export default function UserManagement(
  props: UserManagementProps
): JSX.Element {
  const routeMatch = useRouteMatch(["/users/investor-submit", "/users/list"]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <>
      <PageTitle>Quản lý người dùng</PageTitle>
      <Tabs value={currentTab} sx={{ mb: 2 }}>
        <Tab
          label="Danh sách đăng ký Investor"
          value="/users/investor-submit"
          to="/users/investor-submit"
          component={Link}
        />
        <Tab
          label="Danh sách người dùng"
          value="/users/list"
          to="/users/list"
          component={Link}
        />
      </Tabs>
      <Outlet />
    </>
  );
}

import { Card, CardProps } from "@mui/material";

type PageCardProps = Omit<CardProps, "variant">;
export default function PageCard({ sx, ...props }: PageCardProps): JSX.Element {
  return (
    <Card
      variant={"outlined"}
      sx={{
        boxShadow:
          "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
        border: "none",
        ...sx,
      }}
      {...props}
    />
  );
}

import { css } from "styled-components";
import styled from "styled-email-components";

export const mailCssBase = css`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
`;

export const MailTable = styled.table`
  ${mailCssBase}
  width:100%;
`;
export const MailTd = styled.td`
  ${mailCssBase}
`;

export const MailH2 = styled.h2`
  font-weight: normal;
  margin: 0;
  font-size: 24px;
  margin-bottom: 10px;
`;

export const MailP = styled.p`
  margin: 0;
  color: #777;
  line-height: 150%;
  font-size: 16px;
`;

export const hbsEscaped = {
  var: (varName) => `{{${varName}}}`,
};

import PageTitle from "../../../components/PageTitle";
import { useMailTemplatesQuery } from "../../../types/graphql";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingIndicator } from "@hungphongbk/vth-sdk";
import TemplatePreview from "./template-preview";
import { Link as RouterLink, Outlet } from "react-router-dom";

type SettingsMailTemplateProps = {};
export default function SettingsMailTemplate(
  props: SettingsMailTemplateProps
): JSX.Element {
  const { data, loading } = useMailTemplatesQuery();
  return (
    <>
      <PageTitle>Cài đặt Email Template</PageTitle>
      <Grid container spacing={2}>
        {loading && (
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <LoadingIndicator />
          </Grid>
        )}
        {!loading &&
          data?.mailTemplateDtos &&
          data.mailTemplateDtos.edges.map(({ node }) => (
            <Grid item xs={3} key={node.id}>
              <Card>
                <CardMedia component={TemplatePreview} preview={node} />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {node.templateName}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    component={RouterLink}
                    to={`/settings/mail-template/${node.id}`}
                  >
                    Chỉnh sửa
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Outlet />
    </>
  );
}

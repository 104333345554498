import { Timeline } from "@mui/lab";
import { styled } from "@mui/material";

export const StyledTimeline = styled(Timeline)`
  &.MuiTimeline-root {
    margin-top: -24px;
    margin-bottom: 0;
  }
  .MuiTimelineItem-root::before {
    flex: unset;
    padding-left: 0;
    padding-right: 0;
  }
  .MuiTimelineConnector-root {
    background-color: unset;
    width: 0;
    border-left: 2px dashed #d5d5d5;
  }
`;

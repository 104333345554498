export function omitDeep(value: any, keys: any[]) {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, keys));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (keys.some((_) => _ === k)) return newObject;
      return Object.assign({ [k]: omitDeep(value[k], keys) }, newObject);
    }, {});
  }
  return value;
}

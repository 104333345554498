import { Navigate, Route, Routes } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import Dashboard from "./Dashboard";
import CommonSettings from "./CommonSettings/CommonSettings";
import UserManagement from "./UserManagement/user-management";
import ShowcaseManagement from "./ShowcasesManagement/showcase-management";
import ShowcaseAdd from "./ShowcasesManagement/showcase-add";
import SignIn from "./SignIn";
import React from "react";
import InvestorSubmits from "./UserManagement/investor-submits";
import UserList from "./UserManagement/user-list";
import PreorderManagement from "./preorder-management/preorder-management";
import SettingsMailTemplate from "./settings-management/settings-mail-template";
import MailTemplateEditor from "./settings-management/settings-mail-template/editor";

type AppRoutesProps = unknown;
export default function AppRoutes(props: AppRoutesProps): JSX.Element {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route index element={<CommonSettings />} />
        <Route path="common" element={<CommonSettings />} />
        <Route path="users" element={<UserManagement />}>
          <Route path={""} element={<Navigate replace to={"/users/list"} />} />
          <Route path={"investor-submit"} element={<InvestorSubmits />} />
          <Route path={"list"} element={<UserList />} />
        </Route>
        <Route path="showcases" element={<ShowcaseManagement />}>
          <Route path={":slug"} element={<ShowcaseAdd />} />
        </Route>
        <Route path={"preorders"} element={<PreorderManagement />} />
        <Route
          path={"settings/mail-template"}
          element={<SettingsMailTemplate />}
        >
          <Route path={":id"} element={<MailTemplateEditor />} />
        </Route>
      </Route>
      <Route path={"login"} element={<SignIn />} />
    </Routes>
  );
}

import { createAction, createReducer } from "@reduxjs/toolkit";

type AuthState = {
  loggedIn: boolean;
};

export const login = createAction("@@auth/login"),
  logout = createAction("@@auth/logout");

export const authReducer = createReducer<AuthState>(
  { loggedIn: false },
  (builder) => {
    builder
      .addCase(login, () => ({ loggedIn: true }))
      .addCase(logout, () => ({ loggedIn: false }));
  }
);

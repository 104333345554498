import { Box, Stack, Typography } from "@mui/material";
import { doc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { FirebaseAuthService } from "../../service";
import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// const investorSubmitRef = FirebaseAuthService.firestore

const submitCollection =
  FirebaseAuthService.getFirestoreCollection("submit-investor");
const q = query(submitCollection);

type InvestorSubmitsProps = {};
export default function InvestorSubmits(
  props: InvestorSubmitsProps
): JSX.Element {
  const [list, setList] = useState<any>(undefined);

  const columns: GridColumns = [
    {
      field: "email",
      headerName: "Thông tin cá nhân",
      renderCell: ({ value, getValue, id }) => (
        <Stack gap={0.5}>
          <Typography sx={{ fontWeight: 600 }}>{value}</Typography>
          <Typography>{getValue(id, "phone")}</Typography>
          <Typography>{getValue(id, "job")}</Typography>
        </Stack>
      ),
      flex: 1,
    },
    {
      field: "purpose",
      headerName: "Nội dung",
      renderCell: ({ value, getValue, id }) => (
        <Stack gap={0.5}>
          <Typography>{value}</Typography>
          <Typography>{getValue(id, "method")}</Typography>
          <Typography>{getValue(id, "fund")}</Typography>
        </Stack>
      ),
      flex: 2,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Tác vụ",
      width: 150,
      getActions: ({ id, getValue }) => [
        <GridActionsCellItem
          key={"__approve"}
          icon={
            <CheckCircleIcon
              sx={{
                width: 24,
                height: 24,
                color:
                  getValue(id, "status") === "approved" &&
                  getValue(id, "uid") !== ""
                    ? "#0FD07F"
                    : "inherit",
              }}
            />
          }
          label={"Approve"}
          onClick={() => updateRow(id, { status: "approved" })}
        />,
        <GridActionsCellItem
          key={"__delete"}
          icon={
            <CancelIcon
              sx={{
                width: 24,
                height: 24,
                color:
                  getValue(id, "status") === "rejected" &&
                  getValue(id, "uid") !== ""
                    ? "#FF0000"
                    : "inherit",
              }}
            />
          }
          label={"Delete"}
          onClick={() => updateRow(id, { status: "rejected" })}
        />,
      ],
    },
  ];

  useEffect(() => {
    const unsub = onSnapshot(q, (snapshot) =>
      setList(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      )
    );
    return () => unsub();
  }, []);

  const updateRow = useCallback(async (id, update: any) => {
    const ref = doc(submitCollection, id);
    await updateDoc(ref, update);
  }, []);

  return (
    <Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={list ?? []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          rowHeight={96}
        />
      </Box>
    </Box>
  );
}

import { PreorderNotifyTemplate } from "./preorder-notify";
import { deserialize } from "../utils/serialize";
import MailWrapper from "../mail/MailWrapper";
import { InvestorPromoteNotifyTemplate } from "./investor-promote-notify";

export default {
  "preorder-notify": PreorderNotifyTemplate,
  "investor-promote-notify": InvestorPromoteNotifyTemplate,
} as const;

export const mailDeserialize = (serialized: string) =>
  deserialize(serialized, {
    components: {
      [MailWrapper.displayName]: MailWrapper,
    },
  });

import {
  refetchAllUpdatesInShowcaseQuery,
  refetchOneUpdateInShowcaseQuery,
  useAllUpdatesInShowcaseQuery,
  useOneUpdateInShowcaseQuery,
  usePostAnUpdateInShowcaseMutation,
  useUpdateOneUpdateInShowcaseMutation,
} from "../../../types/graphql";
import PrjUpdateItemEditor from "./prj-update-item-editor";
import { Control } from "react-hook-form";
import { ContentCrudAdapter } from "@hungphongbk/vth-sdk";
import { StyledTimeline } from "./styled";

type PrjUpdateEditorProps = {
  mode: "add" | "edit";
  slug: string;
  control: Control<any>;
};
export default function PrjUpdateEditor({
  mode,
  slug,
  control,
}: PrjUpdateEditorProps): JSX.Element {
  return (
    <StyledTimeline>
      <ContentCrudAdapter
        mode={mode}
        hooks={{
          getAll: [
            useAllUpdatesInShowcaseQuery,
            {
              variables: { slug },
            },
          ],
          getAllPath: "showcase.updates",
          refetchAll: refetchAllUpdatesInShowcaseQuery({
            slug,
          }),
          getOneFn: useOneUpdateInShowcaseQuery,
          refetchOneFn: refetchOneUpdateInShowcaseQuery,
          create: [
            usePostAnUpdateInShowcaseMutation,
            {
              variables: { slug },
            },
          ],
          update: [
            useUpdateOneUpdateInShowcaseMutation,
            {
              variables: { slug },
            },
          ],
        }}
        name={"updates"}
        control={control}
        ItemComponent={(itemProps) => <PrjUpdateItemEditor {...itemProps} />}
      />
    </StyledTimeline>
  );
}

import { createTheme } from "@mui/material/styles";
// noinspection ES6UnusedImports
import {} from "@hungphongbk/vth-sdk/themeAugmentation";
import { PureGrid } from "../components/pure-grid";
import { PlusIcon } from "../assets/icons";
import { Stack, Typography } from "@mui/material";
import { viVN } from "@mui/material/locale";

const primaryMain = "#cba200",
  primaryDark = "#987c00";

declare module "@mui/material/styles" {
  interface VthPalette {
    red: {
      main: string;
      light?: string;
      dark?: string;
    };
    green: {
      main: string;
      light?: string;
      dark?: string;
    };
    yellow: {
      main: string;
      light?: string;
      dark?: string;
    };
    gray: {
      main: string;
      light?: string;
      dark?: string;
    };
  }
  interface PaletteOptions extends VthPalette {}

  interface Palette extends VthPalette {}
}
declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    green: true;
    gray: true;
  }
}

export const theme = createTheme(
  {
    typography: {
      fontFamily: "'Readex Pro', sans-serif",
      fontSize: 13,
      fontWeightBold: 600,
    },
    palette: {
      primary: {
        main: primaryMain,
        dark: primaryDark,
      },
      red: {
        main: "#FF0000",
      },
      green: {
        main: "#0FD07F",
        dark: "#008F54",
      },
      yellow: {
        main: "#FFDE50",
        light: "#FFF5CB",
      },
      text: {
        primary: "rgba(0,0,0,0.95)",
        secondary: "rgba(0,0,0,0.65)",
      },
      gray: {
        main: "#ababab",
        light: "#d5d5d5",
        dark: "#ababab",
      },
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            color: "white",
            backgroundColor: primaryDark,
          },
          text: {
            color: primaryDark,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            fontSize: "1.31rem",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontWeight: 700,
            fontSize: "1.25rem",
          },
          subheader: {
            mt: 0.5,
          },
        },
      },
      HighlightFeature: {
        styleOverrides: {
          thumbnail: {
            borderRadius: 8,
          },
        },
      },
      ImageListEditor: {
        defaultProps: {
          ListComponent: PureGrid,
          ImageUploaderProps: {
            children: (
              <Stack direction={"column"} alignItems={"center"}>
                <PlusIcon
                  sx={{ color: "black", height: 36, width: 36, mb: 0.5 }}
                />
                <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                  Hình ảnh
                </Typography>
                <Typography>JPEG, JPG - 1000x1000px</Typography>
                <Typography>Tối đa 1MB</Typography>
              </Stack>
            ),
          },
        },
      },
    },
  },
  viVN
);

import {
  Maybe,
  ShowcaseCreateInputDto,
  ShowcasePriceInput,
} from "../../../types/graphql";
import {
  Control,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormInput, SimpleTableRoot } from "@hungphongbk/vth-sdk";
import TextField from "../../../components/TextField";
import { DesktopDatePicker } from "@mui/lab";

type ShowcasePriceInputProps = {
  control: Control<ShowcaseCreateInputDto, Object>;
  setValue: UseFormSetValue<ShowcaseCreateInputDto>;
};
export default function SaleDataInput({
  control,
  setValue,
}: ShowcasePriceInputProps): JSX.Element {
  const { errors } = useFormState({ control });
  const watchQuantity: Maybe<ShowcasePriceInput> = useWatch({
    control,
    name: "expectedQuantity",
  });

  useEffect(() => {
    if (
      watchQuantity?.pioneer &&
      watchQuantity?.promo &&
      watchQuantity?.preorder
    ) {
      const [pioneer, promo, preorder] = [
        watchQuantity.pioneer,
        watchQuantity.promo,
        watchQuantity.preorder,
      ];
      setValue("expectedQuantity", {
        pioneer,
        promo,
        preorder,
        regular: pioneer + promo + preorder,
      });
    }
  }, [
    setValue,
    watchQuantity?.pioneer,
    watchQuantity?.promo,
    watchQuantity?.preorder,
  ]);

  useEffect(() => {
    return () => {
      setValue("expectedQuantity", undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={4} sx={{ mb: 2 }}>
      <Grid item xs={7}>
        <Stack gap={1}>
          <Typography variant={"h6"}>SL mở bán dự kiến</Typography>
          <SimpleTableRoot error={errors.expectedSalePrice}>
            <table>
              <thead>
                <tr>
                  <th>Tiên phong</th>
                  <th>Khuyến mãi</th>
                  <th>Đặt trước</th>
                  <th>Tổng</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <FormInput
                      name={"expectedQuantity.pioneer"}
                      control={control}
                      component={SimpleTableRoot.TextEditor}
                      type={"number"}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={"expectedQuantity.promo"}
                      control={control}
                      component={SimpleTableRoot.TextEditor}
                      type={"number"}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={"expectedQuantity.preorder"}
                      control={control}
                      component={SimpleTableRoot.TextEditor}
                      type={"number"}
                    />
                  </td>
                  <td className={"uneditable"}>
                    {typeof watchQuantity?.regular !== "number"
                      ? "-"
                      : watchQuantity.regular}
                  </td>
                </tr>
              </tbody>
            </table>
          </SimpleTableRoot>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Stack gap={2}>
          <Typography variant={"h6"}>Thời gian chạy chiến dịch</Typography>
          <FormInput
            name={"expectedSaleAt"}
            control={control}
            component={DesktopDatePicker}
            label="Thời gian bắt đầu"
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField size={"small"} {...params} />}
            defaultValue={null}
            showDaysOutsideCurrentMonth
            minDate={new Date()}
          />
          <FormInput
            name={"expectedSaleEndAt"}
            control={control}
            component={DesktopDatePicker}
            label="Thời gian kết thúc"
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField size={"small"} {...params} />}
            defaultValue={null}
            showDaysOutsideCurrentMonth
            minDate={new Date()}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

import axios from "axios";
import { getPersistAuth } from "../service/firebase-auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(async (config) => {
  const { token } = await getPersistAuth();
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${token}`,
    },
  };
});

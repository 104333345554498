import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type PageTitleProps = Omit<TypographyProps, "variant" | "component">;
const PageTitle = forwardRef(function PageTitle(
  { sx, ...props }: PageTitleProps,
  ref: any
): JSX.Element {
  return (
    <Typography
      ref={ref}
      variant={"h4"}
      component={"h1"}
      sx={{ mb: 3, ...sx }}
      {...props}
    />
  );
});

export default PageTitle;

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  AuthRoleType,
  refetchAdminQueryAllUsersQuery,
  refetchAdminQueryOneUserQuery,
  useAdminQueryAllUsersQuery,
  useAdminUpdateOneUserMutation,
} from "../../types/graphql";
import { useMemo } from "react";

type UserListProps = {};
export default function UserList(props: UserListProps): JSX.Element {
  const { data, loading: fetching } = useAdminQueryAllUsersQuery();
  const [changeRole, { loading: changing }] = useAdminUpdateOneUserMutation({});

  const loading = fetching || changing;

  const handleSwitchRole = (uid: string) => async (e, newRole) => {
    await changeRole({
      variables: { uid, role: newRole as unknown as AuthRoleType },
      refetchQueries: [
        refetchAdminQueryOneUserQuery({ uid }),
        refetchAdminQueryAllUsersQuery(),
      ],
    });
  };

  const columns: GridColumns = [
    { field: "email", flex: 2 },
    {
      field: "role",
      flex: 1,
      renderCell({ id, value, getValue }) {
        if (value === AuthRoleType.Superadmin)
          return (
            <Typography sx={{ fontWeight: 700, color: "#444" }}>
              SUPERADMIN
              <Typography component={"span"} sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
          );
        return (
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleSwitchRole(id as unknown as string)}
            aria-label="text alignment"
          >
            {[AuthRoleType.User, AuthRoleType.Investor, AuthRoleType.Admin].map(
              (role) => (
                <ToggleButton value={role} key={role}>
                  {role}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        );
      },
    },
  ];

  const allUsers = useMemo(() => {
    if (!data?.getAllUsers) return [];
    return data.getAllUsers.map((u) => ({
      id: u.uid,
      ...u,
    }));
  }, [data]);

  return (
    <Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={allUsers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          rowHeight={72}
          loading={loading}
        />
      </Box>
    </Box>
  );
}

import { hbsEscaped, MailH2, MailP, MailTable, MailTd } from "../mail/css";
import MailWrapper from "../mail/MailWrapper";
import { serialize } from "../utils/serialize";

export const PreorderNotifyTemplate = serialize(
  <MailWrapper>
    <MailTable>
      <tbody>
        <tr>
          <MailTd>
            <MailH2>Cám ơn bạn đã đăng ký đặt trước!</MailH2>
            <MailP>
              Xin chào {hbsEscaped.var("name")}, Vaithuhay Showcase đã nhận được
              yêu cầu đặt trước sản phẩm{" "}
              <strong>{hbsEscaped.var("product_name")}</strong>
            </MailP>
          </MailTd>
        </tr>
      </tbody>
    </MailTable>
  </MailWrapper>
);

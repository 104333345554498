import { gql, useMutation } from "@apollo/client";

const _toggleDoc = gql`
  mutation ToggleShowcaseFeatured($slug: String!, $value: Boolean!) {
    updateOneShowcase(slug: $slug, input: { isFeatured: $value })
  }
`;
export const useToggleShowcaseFeaturedDocument = () => {
  return useMutation(_toggleDoc);
};

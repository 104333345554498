import { Component, ComponentType, createElement, ReactElement } from "react";
import styled from "styled-email-components";

type ObjectType = {
  [index: string]: any;
};

type DeserializableComponent = {
  type: string;
  props: { children: DeserializableComponent[] } & ObjectType;
};

type ReviverOptions = {
  type: string | ComponentType;
  props: { children: DeserializableComponent[] & ObjectType };
  key: string | number;
  components: { [type: string]: ComponentType };
};

type DeserializationElementOpts = {
  components?: { [type: string]: ComponentType };
  reviver?: (args: ReviverOptions) => ReviverOptions;
};

function isStyledEmailComponentType(elementType: any): boolean {
  return (
    elementType.target &&
    elementType.styledComponentId &&
    Array.isArray(elementType.inlineStyle?.rules)
  );
}

function deserializeElement(
  element: DeserializableComponent[] | DeserializableComponent | string | null,
  options: DeserializationElementOpts = {},
  key?: string | number
): any {
  let { components = {}, reviver } = options;

  if (typeof element !== "object") {
    return element;
  }

  if (element === null) {
    return element;
  }

  if (element instanceof Array) {
    return element.map((el, i) => deserializeElement(el, options, i));
  }

  let { props } = element;
  const elementType = element.type;
  const isStyled = isStyledEmailComponentType(elementType);

  if (typeof elementType !== "string" && !isStyled) {
    throw new Error("Deserialization error: element type must be string");
  }

  let type: string | ComponentType = isStyled
    ? //@ts-ignore
      styled(elementType.target)`
        ${
          //@ts-ignore
          elementType.inlineStyle.rules.join("")
        }
      `
    : components[elementType] || elementType.toLowerCase();

  if (props.children) {
    props = { ...props, children: deserializeElement(props.children, options) };
  }

  if (reviver) {
    ({ type, props, key, components } = reviver({
      type,
      props,
      key: key!,
      components,
    }));
  }

  return createElement(type, { ...props, key });
}

export const serialize = <T extends Component | JSX.Element>(component: T) => {
  const getName = (value: string | Function) => {
    if (typeof value === "string") {
      return value;
    } else if (typeof value === "function") {
      // @ts-ignore
      return value.displayName ?? value.name;
    }
    return value;
  };
  const replacer = (key: string, value: any) => {
    switch (key) {
      case "type":
        return getName(value);
      case "_owner":
      case "_store":
      case "ref":
      case "key":
        return;
      default:
        return value;
    }
  };

  return JSON.stringify(component, replacer);
};

export const deserialize = <T extends ReactElement<any>>(
  serializedComponent: string,
  options: DeserializationElementOpts
): T => {
  const componentData = JSON.parse(serializedComponent);

  return deserializeElement(componentData, options) as T;
};

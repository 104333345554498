import {
  MailTemplateDto,
  refetchOneMailTemplateQuery,
  useSetMailTemplateMutation,
} from "../../../types/graphql";
import { useCallback, useEffect, useMemo } from "react";
import templates, { mailDeserialize } from "./templates";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInput } from "@hungphongbk/vth-sdk";
import TextField from "../../../components/TextField";
import ReactDOMServer from "react-dom/server";
import { omit } from "lodash";

type EditorInnerProps = {
  template: MailTemplateDto;
  onClose: () => void;
};
export default function EditorInner({
  template,
  onClose,
}: EditorInnerProps): JSX.Element {
  const form = useForm<MailTemplateDto>({
      defaultValues: {
        ...template,
        _serializedNode: template._serializedNode ?? templates[template.id],
      },
    }),
    { control, watch, setValue, handleSubmit } = form,
    watchSerializedNode = watch("_serializedNode");

  const [updateTemplate] = useSetMailTemplateMutation();

  const onSubmit = useCallback(
    async (values: MailTemplateDto) => {
      const { id, ...rest } = values;
      await updateTemplate({
        variables: {
          input: {
            id: id!,
            update: omit(rest, ["__typename", "name"]),
          },
        },
        refetchQueries: [refetchOneMailTemplateQuery({ id: values.id! })],
      });
      onClose();
    },
    [onClose, updateTemplate]
  );

  const deserialized = useMemo(() => {
    return mailDeserialize(watchSerializedNode!);
  }, [watchSerializedNode]);

  useEffect(() => {
    setValue("html", ReactDOMServer.renderToStaticMarkup(deserialized));
  }, [deserialized, setValue]);

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={8}>
            {deserialized}
          </Grid>
          <Grid item xs={4}>
            <Stack gap={2}>
              <FormInput
                name={"subject"}
                control={control}
                component={TextField}
                label={"Tiêu đề email"}
                fullWidth
                required
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Bỏ qua</Button>
        <Button onClick={handleSubmit(onSubmit)}>Lưu lại</Button>
      </DialogActions>
    </>
  );
}

import PageTitle from "../../components/PageTitle";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColumns,
} from "@mui/x-data-grid";
import { Badge, Box, Button, Chip, Stack, Typography } from "@mui/material";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import {
  PublishStatus,
  refetchShowcaseItemQuery,
  refetchShowcasesQuery,
  ShowcaseFilter,
  ShowcaseStatus,
  useDeleteShowcaseMutation,
  usePublishShowcaseMutation,
  useShowcasesQuery,
} from "../../types/graphql";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import PublishIcon from "@mui/icons-material/Publish";
import Thumbnail from "./components/thumbnail";
import { MediaDto } from "@hungphongbk/vth-sdk";
import LinkTypography from "../../components/LinkTypography";
import { useToggleShowcaseFeaturedDocument } from "./apis";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import {
  usingShowcaseStatusColor,
  usingShowcaseStatusLabel,
} from "../../utils/colors";
import CommentIcon from "@mui/icons-material/Comment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShowcaseManagementFilter from "./components/showcase-management-filter";
import { useDatagridRelayCursorPagination } from "../../utils/use-datagrid-relay-cursor-pagination";
import { QuickSearchToolbar } from "../../components/quick-search-toolbar";
import { throttle } from "lodash";

type ShowcasesManagementProps = {};
export default function ShowcaseManagement(
  props: ShowcasesManagementProps
): JSX.Element {
  const navigate = useNavigate();

  // pagination section
  const {
    page,
    size,
    after,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  } = useDatagridRelayCursorPagination();

  const { enqueueSnackbar } = useSnackbar(),
    [filter, setFilter] = useState<ShowcaseFilter>({
      publishStatus: { eq: PublishStatus.Published },
    }),
    throttleSetFilter = useMemo(() => throttle(setFilter, 150), []);

  const { data, loading: fetching } = useShowcasesQuery({
    variables: {
      size,
      filter,
      after,
    },
  });

  useEffect(() => {
    if (data?.showcases.pageInfo) setPageInfo(data.showcases.pageInfo);
  }, [data, setPageInfo]);

  const [publish, { loading: publishing }] = usePublishShowcaseMutation({
    refetchQueries: [
      refetchShowcasesQuery({
        size,
        filter,
      }),
    ],
  });

  const [deleteShowcase, { loading: deleting }] = useDeleteShowcaseMutation({
    refetchQueries: [
      refetchShowcasesQuery({
        size,
        filter,
      }),
    ],
  });

  const [toggleFeature] = useToggleShowcaseFeaturedDocument();

  const loading = fetching || publishing || deleting;
  const columns: GridColumns = [
    {
      field: "isFeatured",
      headerName: "",
      width: 50,
      filterable: false,
      renderCell: ({ value, getValue, id }) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() =>
            toggleFeature({
              variables: {
                slug: getValue(id, "slug"),
                value: !(value as boolean),
              },
              refetchQueries: [
                refetchShowcaseItemQuery({
                  slug: getValue(id, "slug") as string,
                }),
              ],
            })
          }
        >
          {(value as boolean) ? (
            <StarIcon sx={{ color: "primary.main" }} />
          ) : (
            <StarOutlineIcon sx={{ color: "#888" }} />
          )}
        </Box>
      ),
      sortable: false,
    },
    {
      field: "name",
      headerName: "Tên Showcase",
      flex: 3,
      renderCell: ({ value, getValue, id }) => (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Thumbnail
            sx={{ flexBasis: "60px", flexGrow: 0, flexShrink: 0 }}
            src={getValue(id, "image") as unknown as MediaDto}
          />
          <Box
            sx={{
              "& .MuiTypography-root": {
                minHeight: "unset",
                maxHeight: "unset",
                display: "block",
              },
            }}
          >
            <LinkTypography
              to={getValue(id, "slug") as string}
              sx={{ fontWeight: 700, whiteSpace: "normal" }}
            >
              {value}
            </LinkTypography>
            <Typography>
              <Chip
                label={usingShowcaseStatusLabel(
                  getValue(id, "status") as ShowcaseStatus
                )}
                size={"small"}
                sx={{
                  bgcolor: "rgba(0,0,0,.65)",
                  color: usingShowcaseStatusColor(
                    getValue(id, "status") as ShowcaseStatus
                  ),
                }}
              />
              {getValue(id, "publishStatus") === PublishStatus.Draft && (
                <Chip
                  label="DRAFT"
                  size="small"
                  sx={{
                    bgcolor: "rgba(0,0,0,.65)",
                    color: "#FFBF00",
                    fontWeight: 400,
                    ml: 0.7,
                  }}
                />
              )}
            </Typography>
          </Box>
        </Box>
      ),
      sortable: false,
    },
    {
      field: "commentCount",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: ({ getValue, id }) => (
        <Box
          sx={{
            "& .MuiBadge-badge": { color: "white" },
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridGap: "16px",
            color: "#888",
          }}
        >
          <Badge badgeContent={getValue(id, "commentCount")} color={"primary"}>
            <CommentIcon />
          </Badge>
          <Badge badgeContent={getValue(id, "preorderCount")} color={"primary"}>
            <ShoppingCartIcon />
          </Badge>
        </Box>
      ),
    },
    {
      field: "author",
      headerName: "Người đăng",
      flex: 1,
      sortable: false,
      valueGetter: (p: any) => p.value.name,
    },
    {
      field: "updatedAt",
      headerName: "Chỉnh sửa",
      flex: 1,
      type: "dateTime",
      valueGetter: (p: any) => new Date(p.value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Tác vụ",
      width: 150,
      getActions: ({ getValue, id }) =>
        [
          <GridActionsCellItem
            key={"__edit"}
            icon={<EditIcon />}
            label={"Edit"}
            onClick={() => navigate(`${getValue(id, "slug") as string}`)}
          />,
          <GridActionsCellItem
            key={"__delete"}
            icon={<DeleteOutlineIcon />}
            label={"Delete"}
            onClick={() => handleDeleteShowcase(getValue(id, "slug") as string)}
          />,
          // @ts-ignore
          getValue(id, "publishStatus") === PublishStatus.Draft && (
            <GridActionsCellItem
              key={"__publish"}
              icon={<PublishIcon />}
              label={"Publish"}
              onClick={async () => {
                await publish({
                  variables: {
                    slug: getValue(id, "slug") as string,
                    publishStatus: PublishStatus.Published,
                  },
                });
                enqueueSnackbar("Publish showcase thành công", {
                  variant: "success",
                });
              }}
            />
          ),
        ].filter(
          (v): v is ReactElement<GridActionsCellItemProps> => v !== false
        ),
    },
  ];

  const handleDeleteShowcase = useCallback(
    async (slug: string) => {
      await deleteShowcase({ variables: { slug } });
      enqueueSnackbar("Xoá showcase thành công", { variant: "success" });
    },
    [enqueueSnackbar, deleteShowcase]
  );

  return (
    <>
      <PageTitle>Quản lý Showcases</PageTitle>
      <Stack sx={{ mb: 3 }} direction={"row"} gap={2}>
        <Button
          variant={"contained"}
          component={RouterLink}
          to={"/showcases/add"}
          size={"small"}
        >
          Thêm showcase mới
        </Button>
        <ShowcaseManagementFilter value={filter} onChange={setFilter} />
      </Stack>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={data ? data.showcases.edges.map((s) => s.node) : []}
          rowCount={data?.showcases.totalCount ?? 0}
          columns={columns}
          rowsPerPageOptions={[5, 10]}
          autoHeight
          // checkboxSelection
          disableSelectionOnClick
          disableVirtualization
          rowHeight={90}
          loading={loading}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              placeholder: "Nhập tên showcase để tìm...",
              onChange: (e) =>
                throttleSetFilter({
                  ...filter,
                  name: { iLike: `%${e.target.value}%` },
                }),
            },
          }}
          paginationMode={"server"}
          page={page}
          pageSize={size}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
      <Outlet />
    </>
  );
}

import { serialize } from "../utils/serialize";
import MailWrapper from "../mail/MailWrapper";
import { hbsEscaped, MailH2, MailTable, MailTd } from "../mail/css";

export const InvestorPromoteNotifyTemplate = serialize(
  <MailWrapper>
    <MailTable>
      <tbody>
        <tr>
          <MailTd>
            <MailH2>
              Xin chúc mừng <strong>{hbsEscaped.var("name")}</strong>, bạn đã
              trở thành nhà đầu tư của Vaithuhay Showcase!
            </MailH2>
          </MailTd>
        </tr>
      </tbody>
    </MailTable>
  </MailWrapper>
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { SettingKeys } from "@hungphongbk/vth-sdk";
import {
  apolloClient,
  createOneSetting,
  queryOneSetting,
  updateOneSetting,
} from "../../api";
import {
  CreateSettingMutation,
  CreateSettingMutationVariables,
  QueryOneSettingQuery,
  QueryOneSettingQueryVariables,
  UpdateSettingMutation,
  UpdateSettingMutationVariables,
} from "../../types/graphql";
import { cloneDeep } from "lodash";

export const fetchSetting = createAsyncThunk<any, SettingKeys>(
  "@@setting/fetch-one",
  async (key) => {
    const {
      data: { setting },
    } = await apolloClient.query<
      QueryOneSettingQuery,
      QueryOneSettingQueryVariables
    >({
      query: queryOneSetting,
      variables: { key },
    });
    if (!setting) {
      await apolloClient.mutate<
        CreateSettingMutation,
        CreateSettingMutationVariables
      >({
        mutation: createOneSetting,
        variables: { key, value: {} },
      });
    }
    return { key, value: setting?.value ?? {} };
  }
);
export const updateSetting = createAsyncThunk<
  any,
  UpdateSettingMutationVariables
>("@@setting/update-one", async (payload) => {
  await apolloClient.mutate<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >({
    mutation: updateOneSetting,
    variables: payload,
  });
  return cloneDeep(payload);
});

import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./styles/theme";
import { Dialog, GlobalStyles, ThemeProvider } from "@mui/material";
import { VthThemeProvider } from "@hungphongbk/vth-sdk";
import TextField from "./components/TextField";
import { UploadService } from "./service";
import { SnackbarProvider } from "notistack";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import AppRoutes from "./pages/AppRoutes";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api";
import viVN from "date-fns/locale/vi";

const globalStyles = (
  <GlobalStyles styles={{ body: { letterSpacing: "-0.012em" } }} />
);

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <VthThemeProvider
          config={{
            components: {
              Dialog: Dialog,
              TextField: TextField,
              MultilineTextField: TextField,
            },
            services: {
              uploadService: UploadService.upload,
            },
          }}
        >
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={DateAdapter} locale={viVN}>
              <CssBaseline />
              {globalStyles}
              <AppRoutes />
            </LocalizationProvider>
          </SnackbarProvider>
        </VthThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;

import { css, styled, Typography, TypographyProps } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { forwardRef } from "react";

type LinkTypographyProps = Omit<TypographyProps, "component"> &
  Pick<LinkProps, "to">;

const StyledTypo = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    &,
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
    &:hover {
      color: ${theme.palette.primary.dark};
    }
  `
);

const LinkTypography = forwardRef<HTMLAnchorElement, LinkTypographyProps>(
  function LinkTypography({ to, ...props }, ref): JSX.Element {
    return (
      <StyledTypo ref={ref as any} component={Link as any} to={to} {...props} />
    );
  }
);

export default LinkTypography;

import { PublishStatus, ShowcaseFilter } from "../../../types/graphql";
import { ToggleButton } from "@mui/material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import deepMerge from "deepmerge";

type ShowcaseManagementFilterProps = {
  value: ShowcaseFilter;
  onChange: (value: ShowcaseFilter) => void | Promise<void>;
};
export default function ShowcaseManagementFilter({
  value,
  onChange,
}: ShowcaseManagementFilterProps): JSX.Element {
  const isDraft = value.publishStatus?.eq === PublishStatus.Draft;
  return (
    <>
      <ToggleButton
        size={"small"}
        value={"draft"}
        selected={isDraft}
        onChange={() =>
          onChange(
            deepMerge(value as any, {
              publishStatus: {
                eq: isDraft ? PublishStatus.Published : PublishStatus.Draft,
              },
            })
          )
        }
      >
        <DriveFileRenameOutlineOutlinedIcon />
        Draft
      </ToggleButton>
    </>
  );
}

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import SettingsIcon from "@mui/icons-material/Settings";
import MailIcon from "@mui/icons-material/Mail";

type AppSideMenuProps = {};
export default function AppSideMenu(props: AppSideMenuProps): JSX.Element {
  return (
    <List sx={{ "& .MuiListItemIcon-root": { minWidth: "36px" } }}>
      <ListItem button component={RouterLink} to={"/common"}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={"Thiết lập chung"} />
      </ListItem>
      <ListItem button component={RouterLink} to={"/showcases"}>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary={"Showcases"} />
      </ListItem>
      <List component={"div"} disablePadding>
        <ListItem
          button
          component={RouterLink}
          to={"/preorders"}
          sx={{ pl: 4 }}
        >
          <ListItemIcon>
            <BookmarksIcon />
          </ListItemIcon>
          <ListItemText primary={"Đặt trước"} />
        </ListItem>
      </List>
      <ListItem button component={RouterLink} to={"/users"}>
        <ListItemIcon>
          <ManageAccountsIcon />
        </ListItemIcon>
        <ListItemText primary={"Người dùng"} />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={"Cài đặt"} />
      </ListItem>
      <List component={"div"} disablePadding>
        <ListItem
          button
          component={RouterLink}
          to={"/settings/mail-template"}
          sx={{ pl: 4 }}
        >
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={"Email"} />
        </ListItem>
      </List>
    </List>
  );
}

import * as React from "react";

function PrjUpdateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={22} height={22} rx={3} fill="#D5D5D5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.633 6.045c-.214.073-.31.144-.664.495l-.342.338.748.748.748.747.346-.351c.363-.368.434-.466.5-.692.039-.133.04-.424.003-.565-.093-.35-.413-.665-.753-.743a1.353 1.353 0 00-.586.023zm-3.11 2.94c-.922.92-1.675 1.679-1.675 1.684 0 .006-.07.362-.157.791-.091.457-.152.802-.145.833a.24.24 0 00.16.16c.049.009 1.45-.255 1.597-.301.04-.012.61-.571 1.728-1.689l1.67-1.67-.742-.742a39.695 39.695 0 00-.752-.742c-.005 0-.764.754-1.684 1.675zM7.948 7.691c-.346.084-.663.34-.821.662-.135.274-.127.042-.127 3.483 0 3.481-.01 3.212.143 3.51.12.23.302.406.55.529l.192.095 3.243.005c3.23.005 3.244.005 3.364-.035.4-.133.733-.504.82-.912.022-.109.027-.445.028-1.92V11.32l-.051-.103c-.073-.147-.168-.205-.35-.214-.149-.008-.225.016-.319.101-.116.106-.11-.005-.12 1.946-.01 1.69-.012 1.791-.046 1.86a.495.495 0 01-.263.23c-.054.014-.995.018-3.063.015l-2.985-.005-.093-.054a.416.416 0 01-.146-.147l-.054-.092-.005-2.986c-.004-2.133 0-3.008.016-3.066a.461.461 0 01.31-.284c.07-.015.635-.022 1.815-.022h1.715l.086-.045a.492.492 0 00.145-.129c.053-.076.058-.096.058-.244 0-.146-.005-.169-.056-.242a.43.43 0 00-.155-.13l-.099-.05-1.808.002c-1.506.002-1.827.007-1.924.03z"
        fill="#000"
      />
    </svg>
  );
}

export default PrjUpdateIcon;

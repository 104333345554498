import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  css,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  styled,
  Tab,
  TabProps,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import {
  Control,
  FieldPath,
  get,
  set,
  useForm,
  useFormState,
} from "react-hook-form";
import {
  PublishStatus,
  refetchGetHighlightFeaturesOnShowcaseQuery,
  refetchGetOneHighlightFeatureQuery,
  refetchShowcaseForUpdateQuery,
  ShowcaseCreateInputDto,
  ShowcaseStatus,
  useCreateNewImageListOnShowcaseMutation,
  useCreateOneHighlightFeatureMutation,
  useDeleteOneMediaFromImageListMutation,
  useGetHighlightFeaturesOnShowcaseQuery,
  useGetOneHighlightFeatureQuery,
  useInsertOneMediaToImageListMutation,
  useUpdateOneHighlightFeatureMutation,
} from "../../types/graphql";
import {
  ContentCrudAdapter,
  FormInput,
  HighlightFeature,
  ImageListEditor,
  ImageUploader,
  removeDraftIds,
} from "@hungphongbk/vth-sdk";
import TextField from "../../components/TextField";
import PageCard from "../../components/PageCard";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import { PlusIcon } from "../../assets/icons";
import { useSnackbar } from "notistack";
import ShowcasePriceInput from "./components/sale-data-input";
import { DevTool } from "@hookform/devtools";
import InvestmentInfo from "./components/investment-info";
import { omitDeep } from "../../utils/object";
import ShowcasePriceDataInput from "./components/showcase-price-data-input";
import { pick } from "lodash";
import ShowcaseWrapperHoc from "./showcase-wrapper-hoc";
import PrjUpdateEditor from "./components/prj-update-editor";
import AdminComment from "./components/admin-comment";
import { yupResolver } from "@hookform/resolvers/yup";
import { showcaseAddValidation } from "./showcase-add-validation";

type ShowcaseForm = ShowcaseCreateInputDto;

const StyledTab = styled(Tab)<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      &,
      &.Mui-selected {
        color: #ff0000;
      }
    `}
`;
function ValidatableTab({
  control,
  fields = [],
  ...props
}: TabProps & {
  control: Control<ShowcaseCreateInputDto>;
  fields?: FieldPath<ShowcaseCreateInputDto>[];
}) {
  const { errors } = useFormState({
    control,
    name: fields,
  });
  console.log(errors);
  const haveError = useMemo(() => {
    return fields!.some((f) => Boolean(errors[f]));
  }, [fields, errors]);

  return <StyledTab {...props} error={haveError} />;
}

const ShowcaseAdd = ShowcaseWrapperHoc(function ShowcaseAdd({
  mode,
  slug,
  showcase,
  fetchOneShowcase,
  createShowcase,
  updateShowcase,
}): JSX.Element {
  const navigate = useNavigate();
  const form = useForm<ShowcaseForm>({
      defaultValues: {
        status: ShowcaseStatus.Showcase,
        publishStatus: PublishStatus.Draft,
      } as unknown as ShowcaseForm,
      resolver: yupResolver(showcaseAddValidation),
    }),
    {
      control,
      formState: { errors, isSubmitting, dirtyFields },
      handleSubmit,
      watch,
      setValue: setFormValue,
      reset,
    } = form;

  const title = watch("name");

  useEffect(() => {
    if (showcase)
      reset(
        omitDeep(showcase.showcase, [
          "__typename",
          "author",
          "slug",
          "createdAt",
          "updatedAt",
        ])
      );
  }, [reset, showcase]);

  const showcaseStatus = watch("status"),
    isComingSoon = showcaseStatus === ShowcaseStatus.Coming;

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState("1");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const doCreateShowcase = async (values: ShowcaseForm) => {
    const { data } = await createShowcase({
      variables: {
        input: removeDraftIds(values),
      },
    });
    enqueueSnackbar(
      <>
        Showcase{" "}
        <strong>{data!.createOneShowcase.name} được tạo thành công!</strong>
      </>,
      { variant: "success" }
    );
  };
  const doUpdateShowcase = async (values: ShowcaseForm) => {
    const payload = Object.keys(dirtyFields).reduce((obj, key) => {
      const newObj = { ...obj };
      set(newObj, key, get(values, key));
      return newObj;
    }, pick(values, ["expectedSaleAt", "expectedSaleEndAt"]) as unknown as ShowcaseForm);
    await updateShowcase({ variables: { slug: slug!, input: payload } });
    enqueueSnackbar(<>Showcase được cập nhật thành công!</>, {
      variant: "success",
    });
  };
  const onSubmit = async (values: ShowcaseForm) => {
    try {
      if (mode === "add") await doCreateShowcase(values);
      else await doUpdateShowcase(values);
      navigate("/showcases", { replace: true });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth={"lg"}
      fullWidth
      onClose={() => navigate("/showcases")}
    >
      <DialogTitle>
        {mode === "add" ? "Tạo showcase mới" : `Chỉnh sửa "${title}"`}
        {mode === "edit" && showcase?.showcase?.slug && (
          <AdminComment showcaseSlug={showcase.showcase.slug} />
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={8}>
            <Stack gap={2}>
              <FormInput
                name={"name"}
                control={control}
                component={TextField}
                label={"Tên dự án"}
                fullWidth
              />
              <FormInput
                name={"description"}
                control={control}
                component={TextField}
                label={"Chú thích sản phẩm"}
                fullWidth
                multiline
                minRows={4}
              />
              <ShowcasePriceDataInput control={control} setValue={setValue} />
              <PageCard>
                <CardContent>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {isComingSoon && (
                          <ValidatableTab
                            label={"Mở bán & Đầu tư"}
                            value={"__price"}
                            control={control}
                            fields={["inventory"]}
                          />
                        )}
                        <ValidatableTab
                          label="Thương hiệu"
                          value="1"
                          control={control}
                          fields={["brand"]}
                        />
                        <ValidatableTab
                          label="Tính năng nổi bật"
                          value="2"
                          control={control}
                        />
                        <ValidatableTab
                          label="Ảnh/Video"
                          value="3"
                          control={control}
                        />
                        <ValidatableTab
                          label="Cập nhật sản phẩm"
                          value="4"
                          control={control}
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ px: 0 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography color={"text.secondary"}>
                            Tên thương hiệu
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormInput
                            id={"showcase-brand-name"}
                            name={"brand.name"}
                            control={control}
                            component={TextField}
                            fullWidth
                            size={"small"}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography color={"text.secondary"}>
                            Mô tả thương hiệu
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormInput
                            id={"showcase-brand-desc"}
                            name={"brand.description"}
                            control={control}
                            component={TextField}
                            fullWidth
                            multiline
                            minRows={4}
                            size={"small"}
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2" sx={{ px: 0 }}>
                      <ContentCrudAdapter
                        mode={mode}
                        hooks={{
                          getAll: [
                            useGetHighlightFeaturesOnShowcaseQuery,
                            { variables: { slug } },
                          ],
                          getAllPath: "showcase.highlightFeatures",
                          refetchAll:
                            refetchGetHighlightFeaturesOnShowcaseQuery({
                              slug,
                            }),
                          getOneFn: useGetOneHighlightFeatureQuery,
                          refetchOneFn: refetchGetOneHighlightFeatureQuery,
                          create: [
                            useCreateOneHighlightFeatureMutation,
                            { variables: { slug } },
                          ],
                          update: [
                            useUpdateOneHighlightFeatureMutation,
                            { variables: { slug } },
                          ],
                        }}
                        name={"highlightFeatures"}
                        control={control}
                        ItemComponent={(itemProps) => (
                          <Grid item xs={4}>
                            <HighlightFeature
                              {...itemProps}
                              DialogProps={{ maxWidth: "sm", fullWidth: true }}
                            />
                          </Grid>
                        )}
                        ListComponent={Grid}
                        ListComponentProps={{ container: true, spacing: 2 }}
                        options={{ deletable: true }}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <ImageListEditor
                        control={control}
                        mode={mode}
                        name={"imageLists"}
                        hooks={{
                          refetchQuery: refetchShowcaseForUpdateQuery({ slug }),
                          createNewMutation:
                            useCreateNewImageListOnShowcaseMutation,
                          createNewMutationArgs: {
                            variables: { slug },
                          },
                          addMutation: useInsertOneMediaToImageListMutation,
                          deleteMutation:
                            useDeleteOneMediaFromImageListMutation,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <PrjUpdateEditor
                        mode={mode}
                        slug={slug}
                        control={control}
                      />
                    </TabPanel>
                    {isComingSoon && [
                      <TabPanel value={"__price"} sx={{ px: 0 }}>
                        <ShowcasePriceInput
                          control={control}
                          setValue={setFormValue}
                        />
                        <InvestmentInfo control={control} />
                      </TabPanel>,
                    ]}
                  </TabContext>
                </CardContent>
              </PageCard>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack gap={2}>
              <PageCard>
                <CardHeader title={"Đăng tải"} />
                <CardContent>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      alignItems: "center",
                      gridGap: "12px",
                    }}
                  >
                    <Typography color={"text.secondary"}>
                      Trạng thái Showcase
                    </Typography>
                    <FormInput
                      id={"showcase-status"}
                      name={"status"}
                      component={TextField}
                      control={control}
                      select
                      size={"small"}
                    >
                      <MenuItem value={ShowcaseStatus.Showcase}>
                        Showcase
                      </MenuItem>
                      <MenuItem value={ShowcaseStatus.Idea}>Idea</MenuItem>
                      <MenuItem value={ShowcaseStatus.Coming}>
                        Coming Soon
                      </MenuItem>
                    </FormInput>
                    <Typography color={"text.secondary"}>
                      Trạng thái đăng
                    </Typography>
                    <FormInput
                      id={"showcase-publish-status"}
                      name={"publishStatus"}
                      component={TextField}
                      control={control}
                      select
                      size={"small"}
                    >
                      <MenuItem value={PublishStatus.Draft}>Nháp</MenuItem>
                      <MenuItem value={PublishStatus.Published}>
                        Công khai
                      </MenuItem>
                    </FormInput>
                  </Box>
                </CardContent>
                <CardActions>
                  <LoadingButton
                    startIcon={<PublishIcon />}
                    loading={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    <Typography
                      component={"span"}
                      sx={{ lineHeight: 0, fontWeight: 700 }}
                    >
                      Đăng tải
                    </Typography>
                  </LoadingButton>
                </CardActions>
              </PageCard>
              <PageCard>
                <CardHeader title={"Media"} />
                <CardContent>
                  <FormInput
                    name={"image"}
                    control={control}
                    component={ImageUploader}
                  >
                    <Stack
                      direction={"column"}
                      alignItems={"center"}
                      id={"showcase-thumbnail-upload"}
                    >
                      <PlusIcon
                        sx={{ color: "black", height: 36, width: 36, mb: 0.5 }}
                      />
                      <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                        Banner dự án
                      </Typography>
                      <Typography>JPEG, JPG - 1000x1000px</Typography>
                      <Typography>Tối đa 1MB</Typography>
                    </Stack>
                  </FormInput>
                </CardContent>
              </PageCard>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </Dialog>
  );
});

export default ShowcaseAdd;

import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getPersistAuth } from "../service/firebase-auth";

type RequireAuthProps = PropsWithChildren<unknown>;
export default function RequireAuth({
  children,
}: RequireAuthProps): JSX.Element {
  const [loading, setLoading] = useState(false),
    [token, setToken] = useState<string | undefined>();
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
    getPersistAuth().then(({ token: _token }) => {
      setToken(_token);
      setLoading(true);
    });
  }, []);

  if (!loading) return <></>;
  if (!token) return <Navigate to={"/login"} state={{ from: location }} />;
  return <>{children}</>;
}

import { Box, Drawer } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppSideMenu from "./AppSideMenu";
import UserToolbar from "../components/user-toolbar";

const drawerWidth = 240;

export default function Dashboard() {
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <UserToolbar />
        <AppSideMenu />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Box sx={{ mt: 4, mb: 4, mx: 5 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

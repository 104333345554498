import {
  CardContent,
  CardHeader,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { PlusIcon } from "../../assets/icons";
import { DevTool } from "@hookform/devtools";
import {
  Constants,
  ImageUploader,
  ImageUploaderProps,
  ListEditor,
} from "@hungphongbk/vth-sdk";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import { fetchSetting, updateSetting } from "../../store/actions/settings";
import PageCard from "../../components/PageCard";

const ImageUploaderWrapper = (
  props: Omit<ImageUploaderProps, "uploadService">
) => (
  <Grid item xs={6} sm={4} md={3}>
    <ImageUploader {...props} ratio={"4/3"}>
      <Stack direction={"column"} alignItems={"center"}>
        <PlusIcon sx={{ color: "black", height: 26, width: 26, mb: 0.5 }} />
        <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
          Hình ảnh Banner
        </Typography>
      </Stack>
    </ImageUploader>
  </Grid>
);

type BannerUploadProps = unknown;
export default function BannerUpload(props: BannerUploadProps): JSX.Element {
  const { control, reset, handleSubmit, formState } = useForm({
      defaultValues: {
        key: Constants.SettingKeys.Banner,
        value: { images: [] },
      },
    }),
    setting = useAppSelector(
      (state) => state.settings[Constants.SettingKeys.Banner] as any
    ),
    dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof setting === "undefined")
      dispatch(fetchSetting(Constants.SettingKeys.Banner));
    else
      reset(
        {
          key: Constants.SettingKeys.Banner,
          value: {
            images: (setting.images ?? []).map((i, id) => ({ ...i, id })),
          },
        },
        { keepDirty: false }
      );
  }, [setting, dispatch, reset]);

  const onSubmit = async (values: any) => {
    await dispatch(updateSetting(values));
  };

  return (
    <PageCard>
      <CardHeader
        title={"Banner upload"}
        subheader={
          <>
            Danh sách hình ảnh sẽ hiển thị trên trang chủ của{" "}
            <Link>Showcase Vài thứ hay</Link>
          </>
        }
      />
      <CardContent>
        <Stack gap={2}>
          <ListEditor
            name={"value.images"}
            control={control}
            ItemComponent={ImageUploaderWrapper}
            ListComponent={Grid}
            ListComponentProps={{
              container: true,
              spacing: 1,
            }}
            options={{ deletable: true }}
          />
          <LoadingButton
            variant={"contained"}
            sx={{ alignSelf: "start", px: 3 }}
            disabled={!formState.isDirty}
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {formState.isSubmitted ? "Đã lưu" : "Lưu"}
          </LoadingButton>
        </Stack>
      </CardContent>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </PageCard>
  );
}

import * as yup from "yup";
import { ShowcaseStatus } from "../../types/graphql";

const yupStr = () => yup.string().required(),
  yupNum = () => yup.number().required(),
  yupPriceInput = yup.object({
    pioneer: yupNum(),
    preorder: yupNum(),
    promo: yupNum(),
    regular: yupNum(),
  });

export const showcaseAddValidation = yup.object({
  brand: yup
    .object({
      name: yupStr(),
      description: yupStr(),
    })
    .required(),
  description: yupStr(),
  expectedQuantity: yup.mixed().when("status", {
    is: ShowcaseStatus.Coming,
    then: yupPriceInput.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSaleAt: yup.date().when("status", {
    is: ShowcaseStatus.Coming,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSaleEndAt: yup.date().when("status", {
    is: ShowcaseStatus.Coming,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  expectedSalePrice: yupPriceInput.required(),
  inventory: yup
    .object({
      adCostRate: yupNum(),
      capitalizationRate: yupNum(),
      expectedGrowthRate: yupNum(),
      operatingCostRate: yupNum(),
      revolvingInterval: yupNum(),
    })
    .when("status", {
      is: ShowcaseStatus.Coming,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

import {
  CommentCreateDto,
  CommentDto,
  refetchShowcaseTopCommentQuery,
  usePostAdminTopCommentMutation,
  useShowcaseTopCommentQuery,
  useUpdateAdminTopCommentMutation,
} from "../../../types/graphql";
import {
  IconButton,
  IconButtonProps,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CommentIcon from "../../../assets/icons/comment-icon";
import { useEffect, useMemo } from "react";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useForm } from "react-hook-form";
import { CommentRateSelector, FormInput } from "@hungphongbk/vth-sdk";
import TextField from "../../../components/TextField";
import { LoadingButton } from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { pick } from "lodash";

type AdminCommentProps = {
  showcaseSlug: string;
};
export default function AdminComment({
  showcaseSlug,
}: AdminCommentProps): JSX.Element {
  const { data, loading } = useShowcaseTopCommentQuery({
      variables: { slug: showcaseSlug },
    }),
    commentId = useMemo(() => data?.showcase?.comments[0]?.id, [data]),
    form = useForm<Pick<CommentCreateDto, "content" | "rate">>({
      defaultValues: { content: "", rate: [] },
    }),
    { control, handleSubmit, formState, reset } = form,
    [publishTopComment] = usePostAdminTopCommentMutation(),
    [updateTopComment] = useUpdateAdminTopCommentMutation(),
    { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (commentId) {
      reset(
        pick(data!.showcase!.comments[0] as CommentDto, ["content", "rate"])
      );
    }
  }, [commentId, data, reset]);

  const color = useMemo<IconButtonProps["color"]>(() => {
    if (showcaseSlug && commentId) return "success";
    return "default";
  }, [showcaseSlug, commentId]);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "comment-popup-popover",
  });

  const onSubmit = async (values: any) => {
    if (!commentId) {
      await publishTopComment({
        variables: { slug: showcaseSlug!, ...values },
        refetchQueries: [
          refetchShowcaseTopCommentQuery({ slug: showcaseSlug! }),
        ],
      });
    } else {
      await updateTopComment({
        variables: { id: commentId, update: values },
        refetchQueries: [
          refetchShowcaseTopCommentQuery({ slug: showcaseSlug! }),
        ],
      });
    }
    popupState.close();
    enqueueSnackbar("Đăng tải Admin comment thành công!", {
      variant: "success",
    });
  };

  return (
    <>
      <Tooltip title={"Thêm/Chính sửa comment của Vài Thứ Hay"}>
        <IconButton
          sx={{ height: 40, width: 40, ml: 3 }}
          disabled={typeof showcaseSlug === "undefined"}
          color={color}
          {...bindTrigger(popupState)}
        >
          <CommentIcon />
        </IconButton>
      </Tooltip>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack gap={1} sx={{ width: 400, p: 2 }}>
          <FormInput
            name={"content"}
            control={control}
            label={"Bình luận của admin"}
            component={TextField}
            multiline
            minRows={5}
            size={"small"}
          />
          <FormInput
            name={"rate"}
            control={control}
            component={CommentRateSelector}
            aria-label={"comment rate enums"}
            sx={{ flexWrap: "wrap" }}
          />
          <LoadingButton
            startIcon={<PublishIcon />}
            loading={formState.isSubmitting}
            disabled={!formState.isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography
              component={"span"}
              sx={{ lineHeight: 0, fontWeight: 700 }}
            >
              {commentId ? "Cập nhật" : "Đăng tải"}
            </Typography>
          </LoadingButton>
        </Stack>
      </Popover>
    </>
  );
}

import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router";
import { useDatagridRelayCursorPagination } from "../../utils/use-datagrid-relay-cursor-pagination";
import {
  PreorderDtoFilter,
  Showcase,
  usePreordersQuery,
} from "../../types/graphql";
import { useEffect, useState } from "react";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, Stack, Typography } from "@mui/material";
import Thumbnail from "../ShowcasesManagement/components/thumbnail";

type PreorderManagementProps = {};
export default function PreorderManagement(
  props: PreorderManagementProps
): JSX.Element {
  const navigate = useNavigate(),
    [filter, setFilter] = useState<PreorderDtoFilter>({});

  // pagination section
  const {
    page,
    size,
    after,
    handlePageChange,
    handlePageSizeChange,
    setPageInfo,
  } = useDatagridRelayCursorPagination();

  const { data, loading: fetching } = usePreordersQuery({
    variables: {
      size,
      filter,
      after,
    },
  });

  useEffect(() => {
    if (data?.preorderDtos.pageInfo) setPageInfo(data.preorderDtos.pageInfo);
  }, [data, setPageInfo]);

  const columns: GridColumns = [
    {
      field: "author",
      headerName: "Người đặt",
      flex: 1,
      renderCell({ value }) {
        return (
          <Stack gap={0.5}>
            <Typography variant={"body1"}>{(value as any).name}</Typography>
            <Typography variant={"body2"}>{(value as any).email}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "showcase",
      headerName: "Sản phẩm",
      flex: 1.5,
      renderCell({ value }) {
        const showcase = value as Showcase;
        return (
          <Box sx={{ display: "flex", width: "100%" }}>
            <Thumbnail
              size={50}
              sx={{ flexBasis: "50px", flexGrow: 0, flexShrink: 0 }}
              src={showcase.image}
            />
            <Typography sx={{ fontWeight: 700 }}>{showcase.name}</Typography>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Ngày",
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  return (
    <>
      <PageTitle>Quản lý đặt trước</PageTitle>
      <DataGrid
        columns={columns}
        rows={data ? data.preorderDtos.edges.map((p) => p.node) : []}
        rowCount={data?.preorderDtos.totalCount ?? 0}
        rowsPerPageOptions={[5, 10]}
        autoHeight
        disableSelectionOnClick
        disableVirtualization
        rowHeight={70}
        paginationMode={"server"}
        page={page}
        pageSize={size}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        disableColumnFilter
        disableColumnMenu
      />
    </>
  );
}

import styled from "styled-email-components";
import { mailCssBase, MailTable } from "./css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MailLogo from "../../../../assets/mail/logo.mail.png";

type MailProps = {};

const MailHeaderTable = styled(MailTable)`
    width: 100%;
  `,
  Tr = styled.tr`
    ${mailCssBase}
  `,
  Td = styled.td`
    ${mailCssBase}
    padding:16px;
  `;

export default function MailHeader(props: MailProps): JSX.Element {
  return (
    <MailHeaderTable>
      <tbody>
        <Tr>
          <Td>
            <img src={MailLogo} alt={"Vaithuhay Showcase"} width={80} />
          </Td>
        </Tr>
      </tbody>
    </MailHeaderTable>
  );
}

import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PlusIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54801 0.0112921C4.65872 0.0964594 3.9973 0.284545 3.24847 0.665195C2.36141 1.11608 1.56542 1.82907 0.985018 2.69259C0.739273 3.05821 0.438972 3.67543 0.294781 4.11126C0.178396 4.46304 0.0859453 4.86339 0.0294423 5.26027C-0.00981411 5.53605 -0.00981411 6.46178 0.0294423 6.73757C0.127759 7.42826 0.310432 8.0333 0.595833 8.6135C0.918731 9.26993 1.23501 9.71094 1.75717 10.2328C2.27363 10.7489 2.7218 11.0712 3.35406 11.3811C3.79522 11.5974 4.08006 11.7026 4.5273 11.8148C5.70086 12.1089 6.87787 12.0525 8.02445 11.6472C8.37201 11.5243 8.97871 11.2204 9.29063 11.013C9.99187 10.5467 10.6356 9.88723 11.095 9.16455C11.264 8.89866 11.5356 8.33769 11.6464 8.02568C12.1976 6.47341 12.1017 4.80737 11.3772 3.35167C11.0849 2.7642 10.7594 2.30378 10.3024 1.8312C9.79563 1.30712 9.29856 0.94553 8.63362 0.617195C7.93857 0.274016 7.29097 0.0908316 6.52179 0.0198041C6.28274 -0.00226151 5.73927 -0.00702169 5.54801 0.0112921ZM6.26077 2.95111C6.30796 2.98303 6.37544 3.05044 6.41071 3.10091L6.47486 3.19266L6.4866 4.34371L6.49833 5.49476L7.65014 5.50649L8.80195 5.51821L8.89377 5.58232C8.94426 5.61756 9.01198 5.68538 9.04425 5.73303C9.09416 5.80668 9.10291 5.84638 9.10291 5.99892C9.10291 6.15145 9.09416 6.19115 9.04425 6.26481C9.01198 6.31246 8.94426 6.38027 8.89377 6.41552L8.80195 6.47963L7.65014 6.49135L6.49833 6.50307L6.4866 7.65412L6.47486 8.80517L6.41071 8.89693C6.37544 8.94739 6.30758 9.01507 6.2599 9.04731C6.1862 9.09718 6.14648 9.10593 5.99384 9.10593C5.8412 9.10593 5.80147 9.09718 5.72777 9.04731C5.68009 9.01507 5.61223 8.94739 5.57696 8.89693L5.51281 8.80517L5.50108 7.65412L5.48935 6.50307L4.33754 6.49135L3.18572 6.47963L3.09391 6.41552C2.94699 6.31295 2.88728 6.20506 2.87768 6.02492C2.86743 5.83257 2.93235 5.69401 3.08215 5.58856L3.18206 5.51821L4.33571 5.50649L5.48935 5.49476L5.50108 4.34188L5.51281 3.189L5.58321 3.08916C5.68873 2.93946 5.82738 2.87457 6.01986 2.88482C6.13686 2.89104 6.19603 2.90733 6.26077 2.95111Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

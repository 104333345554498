import { SettingKeys } from "@hungphongbk/vth-sdk";
import { createReducer } from "@reduxjs/toolkit";
import { fetchSetting, updateSetting } from "../actions/settings";

export type SettingsState = {
  [key in SettingKeys]?: unknown;
};

export const settingsReducer = createReducer<SettingsState>({}, (builder) => {
  builder
    .addCase(fetchSetting.fulfilled, (state, { payload }) => {
      state[payload.key] = payload.value;
    })
    .addCase(updateSetting.fulfilled, (state, { payload }) => {
      console.log(payload);
      state[payload.key] = payload.value;
    });
});

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import introspection from "./introspection.json";
import { setContext } from "@apollo/client/link/context";
import { getPersistAuth } from "../service/firebase-auth";
import { relayStylePagination } from "@hungphongbk/vth-sdk";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { token } = await getPersistAuth();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    ...introspection,
    typePolicies: {
      Showcase: { keyFields: ["slug"] },
      PreorderDto: { keyFields: ["id"] },
      User: { keyFields: ["email"] },
      Query: {
        fields: {
          showcases: relayStylePagination([
            "@connection",
            ["filter", "size", "after"],
          ]),
          preorderDtos: relayStylePagination([
            "@connection",
            ["filter", "size", "after"],
          ]),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

// if (process.env.NODE_ENV === "development") {
// @ts-ignore
window.__APOLLO_CLIENT__ = apolloClient;
// }

export { apolloClient };
